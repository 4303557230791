import { useState } from "react";
import { UserInfo, getUserInfo } from "./common/user-info";
import ErrorScreen, { ErrorScreenType } from "./components/shared/ErrorScreen";
import PageLoadingScreen from "./components/PageLoadingScreen";
import Header from "./components/shared/Header";
import { Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import Footer from "./components/shared/Footer";
import GlobalStateProvider from "./context/GlobalStateContext";
import { GameState } from "./common/global-enums";
import { BrowseGamesResponse } from "./common/browse-games-response";
import { browseGames } from "./firebase/browse-games-wrapper";
import DailyGamesBanner from "./components/browse/DailyGamesBanner";
import RecentGamesSection from "./components/browse/RecentGamesSection";
import PopularGamesSection from "./components/browse/PopularGamesSection";
import SelectGameBanner from "./components/browse/SelectGameBanner";
import { DateTime } from "luxon";

const BrowseGamesPage = (): React.ReactElement => {
    const isAfterDailyQuestsEnds = DateTime.now() >= DateTime.local(2025, 1, 2, 0, 0);

    const [isPageLoading, setIsPageLoading] = useState(true);
    const [browseGamesResponse, setBrowseGamesResponse] = useState<BrowseGamesResponse>();
    const [initialUserInfo, setInitialUserInfo] = useState<UserInfo>(UserInfo.createEmpty());
    const [errorScreenType, setErrorScreenType] = useState<ErrorScreenType>();

    if (isPageLoading) {
        getUserInfo().then((userInfo: UserInfo) => {
            browseGames(userInfo.getUserId()).then((response: BrowseGamesResponse) => {
                setBrowseGamesResponse(response);
                setIsPageLoading(false);
                setInitialUserInfo(userInfo);
            }).catch((error) => {
                if (error.code === "functions/not-found") {
                    setErrorScreenType(ErrorScreenType.NOT_FOUND);
                } else {
                    setErrorScreenType(ErrorScreenType.GENERIC_ERROR);
                }
            });
        }).catch((error) => {
            if (error.code === "functions/not-found") {
                setErrorScreenType(ErrorScreenType.NOT_FOUND);
            } else {
                setErrorScreenType(ErrorScreenType.GENERIC_ERROR);
            }
        });
    }

    return (
        <>
            {isPageLoading && errorScreenType === undefined && <PageLoadingScreen />}
            {
                !isPageLoading && errorScreenType === undefined &&
                <GlobalStateProvider
                    acceptedVariants={[]}
                    acceptedThemeWords={[]}
                    faq={[]}
                    globalGameStats={undefined}
                    gameId={""}
                    secretWord={""}
                    theme={""}
                    dailyWordNumber={0}
                    playerUserId={""}
                    initialGameState={GameState.NOT_STARTED}
                    initialGameTranscriptRecords={[]}
                    initialHints={[]}
                    initialGameSessionId={""}
                    initialUserInfo={initialUserInfo}
                    initialCurrentWinStreak={0}
                >
                    <Header />
                    <Container>
                        <Row className="justify-content-sm-center mt-4">
                            <Col lg={{ span: 10 }} xl={{ span: 9 }} xxl={{ span: 8 }}>
                                <SelectGameBanner />
                                <Card className="mb-4">
                                    <Card.Header className=""><h5 className="text-primary text-center">Custom Quests</h5></Card.Header>
                                    <Card.Body>
                                        <Tabs className="bg-light" defaultActiveKey="recent" fill justify variant="pills">
                                            <Tab eventKey="recent" title="Recent">
                                                <RecentGamesSection games={browseGamesResponse!.recentGames} />
                                            </Tab>
                                            <Tab eventKey="popular" title="Popular">
                                                <PopularGamesSection games={browseGamesResponse!.popularGames} />
                                            </Tab>
                                        </Tabs>
                                    </Card.Body>
                                </Card>
                                {!isAfterDailyQuestsEnds && <div className="mb-4">
                                    <DailyGamesBanner />
                                </div>}
                            </Col>
                        </Row>
                    </Container>
                    <Footer />
                </GlobalStateProvider>
            }
            {errorScreenType !== undefined && <ErrorScreen type={errorScreenType} />}

        </>

    );
}

export default BrowseGamesPage;