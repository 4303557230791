import Container from 'react-bootstrap/Container';
import AccountButton from '../auth/AccountButton';
import { Col, Image, Nav, Navbar, Row } from 'react-bootstrap';
import AnnouncementsButton from '../AnnouncementsButton';
import TutorialModal from '../TutorialModal';
import { DateTime } from 'luxon';

type Props = {
    onlyShowBrand?: boolean
};

// Header at the top of the page.
function Header(props: Props) {
    const isAfterDailyQuestsEnds = DateTime.now() >= DateTime.local(2025, 1, 2, 0, 0);

    const logo = <a href={process.env.REACT_APP_PUBLIC_URL}><Image src={process.env.REACT_APP_PUBLIC_URL + "logo.png"} height={32} /></a>;
    const floatingButtons = <div className="float-right">
        <AccountButton />
        <AnnouncementsButton />
    </div>;

    const navLinks = <>
        {isAfterDailyQuestsEnds && <Nav.Link href={process.env.REACT_APP_PUBLIC_URL} eventKey="browse">Browse</Nav.Link>}
        {!isAfterDailyQuestsEnds && <>
            <Nav.Link href={process.env.REACT_APP_PUBLIC_URL} eventKey="daily">Today's Quest</Nav.Link>
            <Nav.Link href={process.env.REACT_APP_PUBLIC_URL + "browse"} eventKey="browse">Browse</Nav.Link>
        </>
        }
        <Nav.Link href={process.env.REACT_APP_PUBLIC_URL + "create"} eventKey="create">Create</Nav.Link>
    </>;

    let activeNavKey = "";
    if (window.location.pathname === "/") {
        if (isAfterDailyQuestsEnds) {
            activeNavKey = "browse";
        } else {
            activeNavKey = "daily";
        }
    } else if (window.location.pathname === "/browse") {
        activeNavKey = "browse";
    } else if (window.location.pathname === "/create") {
        activeNavKey = "create";
    }
    return (
        <div className="bg-body-tertiary p-3">
            <Container>
                <Row className="justify-content-sm-center">
                    <Col lg={{ span: 9 }} xl={{ span: 8 }} xxl={{ span: 8 }}>
                        <div className="d-md-none">
                            <div>{logo}</div>
                            {!props.onlyShowBrand && floatingButtons}
                            <Nav variant="underline" activeKey={activeNavKey}>
                                {navLinks}
                            </Nav>
                        </div>
                        <div className="d-none d-md-block">
                            <Navbar>
                                <Container>
                                    <Navbar.Brand>{logo}</Navbar.Brand>
                                    <Nav variant="underline" className="me-auto" activeKey={activeNavKey}>
                                        {navLinks}
                                    </Nav>
                                    {!props.onlyShowBrand && floatingButtons}
                                </Container>
                            </Navbar>
                        </div>
                    </Col>
                </Row>
            </Container>
            {!props.onlyShowBrand && <TutorialModal />}
        </div>
    );
}

export default Header;