import { Button, Col, Row } from "react-bootstrap";
import { TOP_LEVEL_COMPONENT_COL_LG, TOP_LEVEL_COMPONENT_COL_MD, TOP_LEVEL_COMPONENT_COL_XL, TOP_LEVEL_COMPONENT_COL_XXL } from "../../common/display-utils";
import { DateTime } from "luxon";

function DailyGamesBanner() {
    const isAfterDailyQuestsEnds = DateTime.now() >= DateTime.local(2025, 1, 2, 0, 0);
    return (
        <Row className="justify-content-sm-center">
            <Col md={TOP_LEVEL_COMPONENT_COL_MD} lg={TOP_LEVEL_COMPONENT_COL_LG} xl={TOP_LEVEL_COMPONENT_COL_XL} xxl={TOP_LEVEL_COMPONENT_COL_XXL}>
                {isAfterDailyQuestsEnds && <Button variant="primary" size="lg" className="w-100" href={process.env.REACT_APP_PUBLIC_URL + "dailyGames"}>Or play the 225 official quests</Button>}
                {!isAfterDailyQuestsEnds && <Button variant="primary" size="lg" className="w-100" href={process.env.REACT_APP_PUBLIC_URL + "dailyGames"}>Or play the previous daily quests</Button>}
            </Col>
        </Row>
    );
}

export default DailyGamesBanner;