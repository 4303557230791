import { Button, Col, Image, Row } from "react-bootstrap";
import { TOP_LEVEL_COMPONENT_COL_LG, TOP_LEVEL_COMPONENT_COL_MD, TOP_LEVEL_COMPONENT_COL_XL, TOP_LEVEL_COMPONENT_COL_XXL } from "../../common/display-utils";
import { DateTime } from "luxon";
import '../../styles/RobotContainer.scss';

function SelectGameBanner() {
    const isAfterDailyQuestsEnds = DateTime.now() >= DateTime.local(2025, 1, 2, 0, 0);
    if (!isAfterDailyQuestsEnds) {
        return <></>;
    }

    return (
        <Row className="justify-content-sm-center">
            <Col md={TOP_LEVEL_COMPONENT_COL_MD} lg={TOP_LEVEL_COMPONENT_COL_LG} xl={TOP_LEVEL_COMPONENT_COL_XL} xxl={TOP_LEVEL_COMPONENT_COL_XXL}>
                <div className="robot-container">
                    <div className={"image-container"}>
                        <Image src={process.env.REACT_APP_PUBLIC_URL + 'happy-robot.webp'} className="w-100" />
                    </div>
                    <div className="speech-bubble">
                        Select one of the custom-created quests below or play the 225 official quests!

                        <Button href={process.env.REACT_APP_PUBLIC_URL + 'dailyGames'} variant="primary" className="w-100 mt-2 mb-2" size="sm">
                            Official Quests
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default SelectGameBanner;